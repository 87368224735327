<template>
  <Modal
    id="christmasPopup"
    :visible="isPopupVisible"
    class="christmasPopup"
    @click="onHide"
  >
    <template #header></template>
    <template #body>
      <Button
        id="christmasPopup__buttonClose"
        type="button"
        class="btn-close"
        variant="link"
        @click="onHide"
      />
      <img ref="bannerImage" :src="popup" alt="Banner" class="banner-image" />
    </template>
    <template #footer></template>
  </Modal>
</template>

<script>
import Button from "@/modules/components/base/Button.vue";
import christmasPopup from "@/assets/img/eDTP_popup_prace1.png";
import Modal from "@/modules/components/base/Modal.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "ChristmasPopup",

  components: { Button, Modal },

  setup() {
    const popup = ref(christmasPopup);
    const isPopupVisible = ref(false);

    const onHide = () => {
      window.localStorage.setItem("stmPopupDate", new Date().getTime());
      isPopupVisible.value = false;
    };

    const shouldShow = () => {
      let lastDate = parseInt(window.localStorage.getItem("stmPopupDate"), 10);
      let currentDate = new Date().getTime();

      if (!lastDate || isNaN(lastDate)) {
        lastDate = 0;
      }

      const endOf2024 = new Date(2025, 0, 20).getTime();
      return currentDate < endOf2024 && currentDate - lastDate > 43200000;
    };

    const checkPopupVisibility = () => {
      if (shouldShow()) {
        isPopupVisible.value = true;
      }
    };

    const route = useRoute();
    watch(
      () => route.fullPath,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          checkPopupVisibility();
        }
      }
    );

    onMounted(() => {
      checkPopupVisibility();
    });

    return {
      onHide,
      popup,
      shouldShow,
      isPopupVisible,
    };
  },
};
</script>
